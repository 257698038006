import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import ScrollingLogo from "../../../V2/Banners/ScrollingLogo"

const StoryblokToastNotificationBanner = ({
  blok,
}: Storyblok.BlokProps<Storyblok.ScrollingBanner>) => {
  return (
    <ScrollingLogo
      backgroundColor={blok.backgroundColor}
      imageUrl={blok.image.filename}
      title={blok.title}
      rounded={blok.rounded}
      callout={blok.callout ? blok.callout[0] : undefined}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}

export default StoryblokToastNotificationBanner
