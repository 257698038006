import classNames from "classnames"
import React from "react"

import { Color } from "../../../constants/V2/color"
import GatsbyStoryblokImage from "../../elements/V2/GatsbyStoryblokImage"
import Section from "../../elements/V2/Section"
import Typography from "../../elements/V2/Typography"

import Callout from "@components/elements/V2/Callout"
import {
  getAccentColor,
  getBackgroundColorClass,
  getShadowColorClass,
} from "@utils/V2/color"

type ScrollingLogoProps = {
  title?: string
  imageUrl: string
  backgroundColor: Color
  rounded?: boolean
  callout?: Storyblok.Callout
}

const ScrollingLogo = ({
  title,
  imageUrl,
  backgroundColor,
  rounded = true,
  callout,
}: ScrollingLogoProps) => {
  return (
    <Section
      className={classNames(
        getAccentColor(backgroundColor),
        getBackgroundColorClass(backgroundColor),
        "pb-lg -mb-lg relative pt-16 md:pt-20 lg:pt-32",
        {
          "rounded-t-xl -mt-xl": rounded,
        }
      )}
      data-testid="scrolling-logo-component"
    >
      <div className="flex flex-row items-center gap-16 pb-10 md:pb-12">
        {callout && (
          <Callout
            color={getAccentColor(backgroundColor)}
            text={callout.text}
            highlightedWord={callout.highlightedWord}
            hasBorder={false}
          />
        )}
        {title && (
          <Typography
            text={title}
            weight="book"
            font="grotesk"
            size="body-lg"
            color={getAccentColor(backgroundColor)}
          />
        )}
      </div>
      <div className="relative flex items-center overflow-hidden">
        <div className="flex animate-[scroll_100s_linear_infinite]">
          {/* Mapping the img to avoid the white space before the marquee completes and the rerender jump */}
          {Array.from({ length: 4 }).map((_, index) => (
            <GatsbyStoryblokImage
              image={imageUrl}
              alt="Marquee logo"
              className="max-w-none"
              key={`scrolling-logo-${index}`}
              width={1336}
              height={46}
            />
          ))}
        </div>
        <div
          className={classNames(
            "absolute left-0 h-full shadow-[0_0_5px_5px]",
            getShadowColorClass(backgroundColor)
          )}
        />
        <div
          className={classNames(
            "absolute right-0 h-full shadow-[0_0_5px_5px]",
            getShadowColorClass(backgroundColor)
          )}
        />
      </div>
    </Section>
  )
}

export default ScrollingLogo
